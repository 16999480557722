/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Homepage BFF API
 * The Homepage BFF (Backend For Frontend) API provides endpoints to proxy and retrieve and transform content for the UI. It acts as an intermediary between the frontend and various backend services(i.e. Contentful).
 * OpenAPI spec version: 1.0.0
 */
import { fetchInstanceContentful } from '../lib/shared-domain-contentful-api'

import type { AnnouncementDto, AnnouncementsBody } from './api.schemas'

/**
 * Retrieves the details of the current announcement for the user.
Supply the announcementSet ID and the current date to get the announcement.
 */
export const getAnnouncement = (
  date: string,
  id: string,
  announcementsBody: AnnouncementsBody
) => {
  return fetchInstanceContentful<AnnouncementDto>({
    url: `/announcements/${date}/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: announcementsBody,
  })
}

export type GetAnnouncementResult = NonNullable<
  Awaited<ReturnType<typeof getAnnouncement>>
>
