import cx from 'classnames'
import React from 'react'

import { IconButton } from '../../../atoms/icon-button'

import { ActionButton } from './components/action-button'
import {
  announcementTypeToBackgroundColorMap,
  announcementTypeToIconColorMap,
} from './styles'
import type { AnnouncementProps } from './types'

/**
 * A component that displays an announcement message.
 * This component uses the container queries tailwindcss plugin to adjust the layout based on the size of the container:
 *
 * ***Size Variants***:
 *
 *  **Small**: Below `min-width:512px`.
 *
 *  **Medium**: `min-width:768px`.
 *
 *  **Large**: `min-width:1024px`.
 */
export const Announcement = ({
  color = 'primary',
  action,
  adornment,
  title,
  description,
  onDismiss,
  disabled,
}: AnnouncementProps) => {
  const titleId = React.useId()

  const Adornment = adornment
    ? adornment(announcementTypeToIconColorMap[color])
    : null

  return (
    <aside
      className={`@container w-full ${announcementTypeToBackgroundColorMap[color]}`}
      role="region"
      aria-labelledby={titleId}
    >
      <div className="@md:flex-row @md:items-center @md:px-32 @lg:px-56 relative mx-auto flex w-[min(1660px,100%)] flex-col gap-16 px-24 py-16">
        <div
          className={cx(
            '@lg:flex-row @lg:items-center @lg:gap-x-16 flex flex-col flex-wrap gap-8 text-neutral-900'
          )}
        >
          <p
            className="body-strong @lg:mr-0 @lg:items-center mr-32 flex space-x-8 *:mr-8"
            id={titleId}
          >
            {Adornment}
            {title}
          </p>

          {description ? <p className="small">{description}</p> : null}
        </div>
        <span
          className={cx('@md:ml-auto', {
            '[&>a:first-child]:bg-primary-300 [&>a:first-child]:border-primary-300 [&>a:first-child]:cursor-not-allowed':
              action?.disabled,
          })}
        >
          {action ? <ActionButton action={action} /> : null}
        </span>
        {onDismiss ? (
          <span className="@md:relative @md:right-0 @md:flex absolute right-24">
            <IconButton
              iconType="Close"
              onClick={onDismiss}
              color={color}
              size="small"
              aria-label="Close"
              disabled={disabled}
            />
          </span>
        ) : null}
      </div>
    </aside>
  )
}
